<template>
  <div class="view-circle-article-page">
    <v-row justify="center" class="pa-5">
      <v-col md="8">
        <div v-if="articleObject">
          <v-img
            v-if="articleObject.cover_image"
            :src="articleObject.cover_image"
            contain
          ></v-img>
          <div class="mt-3">
            <v-row align="center">
              <v-col cols="12">
                <h2  class="primary--text">
                  {{ articleObject.title }}
                </h2>
                <p v-if="articleObject.creator" class="body-2 mb-0">
                  By {{ articleObject.creator.full_name }}
                </p>
              </v-col>
            </v-row>

            <v-row class="my-0">
              <v-col>
                <v-chip class="mr-1" color="primary" outlined small v-for="tag in articleObject.tags" :key="tag.id">
                  {{ tag.title }}
                </v-chip>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <v-card>
                  <v-card-text class="pa-2">
                    <div class="ql-editor" v-html="articleObject.description"></div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="2" class="px-2">
                <v-btn color="transparent" dark elevation="0">
                  <v-icon color="red" v-if="is_liked" @click="toggleLike()">
                    mdi-cards-heart
                  </v-icon>
                  <v-icon v-else color="red" @click="toggleLike()">
                    mdi-cards-heart-outline
                  </v-icon>
                  <strong class="primary--text mx-2" @click="getLikedByDetails()">{{ articleObject.liked_by.length }} likes</strong>
                </v-btn>
              </v-col>
              <v-col cols="6" sm="2" align="right">
                <v-btn color="transparent" class="px-0" dark elevation="0" style="pointer-events: none">
                  <v-icon color="blue">mdi-chat-processing</v-icon>
                  <strong class="primary--text mx-2">
                    {{ articleObject.comments? articleObject.comments.length : 0 }} replies
                  </strong>
                </v-btn>
              </v-col>
              
              <v-col v-if="!articleObject.disable_comments" cols="12" class="py-0">
                <v-btn color="primary" block dark elevation="0" @click="openCommentSheet()">
                  Reply
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="articleObject.comments">

              <v-col>
                <v-list v-for="comment in articleObject.comments" :key="comment.id">
                  <v-card elevation="0" class="grey lighten-5 rounded">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img :src="comment.user.image"> </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ comment.user.full_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption">
                          {{ comment.institution_name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-content>
                      <v-list-item-subtitle align="right">
                        {{comment.last_edited ?
                          moment(comment.last_edited).fromNow() :
                          moment(comment.created).fromNow()}}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                      <v-list-item-icon 
                        v-if="comment.user.id == currentUser.id || currentUser.is_support_account"
                        class="align-self-center mr-n3">
                        <intract-drop-down-menu
                          :options-list="getCommentDropdownOptions(comment)"
                        />
                      </v-list-item-icon>
                    </v-list-item>

                    <v-card-text class="mt-n5">
                      <div class="black--text subtitle-1">
                        {{ comment.text }}
                      </div>
                      <!-- Comment images -->
                      <v-row
                        class="mt-2"
                        dense
                        v-if="comment.images && comment.images.length"
                      >
                        <v-col
                          cols="4"
                          md="3"
                          v-for="image in comment.images"
                          :key="image.id"
                        >
                          <v-card outlined elevation="0">
                            <v-img
                              class="cursor-pointer"
                              @click="showFullScreenCarousel(image, comment.images)"
                              :src="image.image"
                              aspect-ratio="1"
                            >
                            </v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    
    <!-- </v-fab-trcommentition> -->
      <intract-bottom-sheet
        title="Likes"
        :visible="likesBottomSheetVisible"
        @close="likesBottomSheetVisible=false"
        description="Following people have liked this circle article"
        persistent
      >
        <template>
          <!-- <intract-smart-list
          v-if="likesBottomSheetVisible"
          class="mt-4"
          ref="all-circle-articles-smart-list"
          paginated
          :endpoint="circleArticlesEndpoint"
          :item-options="{ title: 'title' }"
          disable-search
        > -->
          <!-- <template v-slot:list-item="{ item: article }"> -->
            <v-list
              class="mb-3 post-card mx-3"
            >
              <v-list-item
                v-for="user in likedByDetails"
                :key="user.id"
              >
                <v-list-item-avatar>
                  <v-img :src="user.image"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="subtitle-2">
                  {{ user.full_name }}
                </v-list-item-content>

              </v-list-item>

            </v-list>
            
          <!-- </template> -->
        <!-- </intract-smart-list> -->
        </template>
      </intract-bottom-sheet>

      <!-- COMMENT CREATE EDIT SHEET -->
    <intract-create-edit-sheet
      persistent
      :title="
          commentCreateEditSheet.editId
            ? 'Edit Comment'
            : 'Comment on Article'        
      "
      :submit-button-text="
          commentCreateEditSheet.editId
            ? 'Edit'
            : 'Comment'
      "
      :visible="commentCreateEditSheet.visible"
      @close="commentCreateEditSheet.visible = false; commentCreateEditSheet.editId = null"
      :fields="commentformFields"
      enable-media
      :endpoint="endpoints.circleCommentViewSet"
      :edit-id="commentCreateEditSheet.editId"
      :edit-success-message="commentCreateEditSheet.editSuccessMessage"
      :data-object="commentCreateEditSheet.obj"
      :create-success-message="commentCreateEditSheet.createSuccessMessage"
      @objectCreated="commentCreatedOrEdited"
      @updateObject="(obj) => (commentCreateEditSheet.obj = obj)"
    />

    <full-screen-image-carousel
      :initial-image="initialImage"
      :images="fullScreenCarouselImages"
      :visible="fullScreenImageOverlay"
      @closeDialog="fullScreenImageOverlay = false"
    />

    <confirmation-dialog
      title="Are you sure you want to delete this comment?"
      description="This action cannot be undone" 
      :visible="showDeleteCommentDialog"
      delete-dialog
      @successCallback="deleteComment"
      @failureCallback="showDeleteCommentDialog = false"
    /> 

  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import IntractBottomSheet from "@components/generics/IntractBottomSheet.vue";
import FullScreenImageCarousel from "@components/dialogs/FullScreenImageCarousel";

import moment from "moment";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "ViewCircleArticle",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractDropDownMenu,
    IntractCreateEditSheet,
    IntractBottomSheet,
    FullScreenImageCarousel
  },
  
  data() {
    return {
      moment: moment,
      articleObject: null,
      likesBottomSheetVisible: false,
      likedByDetails: null,
      is_liked: false,
      showDeleteCommentDialog: false,
      toBeDeletedComment: null,
      initialImage: null,
      fullScreenImageOverlay: false,
      fullScreenCarouselImages: [],
      commentCreateEditSheet: {
        visible: false,
          createSuccessMessage: "Comment sent successfully!",
          editSuccessMessage: "Comment edited successfully!",
          editId: null,
          obj: {
            user: {},
            article: {},
            images: [],
        },
      }, 
    };
  },
  computed: {
    appBarOptions(){
      return this.currentUser.is_student ? null : {
        iconButton:{
          icon: "mdi-share-variant",
          action: () => {
            this.Helper.copyText(window.location.href);
            this.showSnackbar({
              title: "Link copied to clipboard",
              // text: err.response.data.message,
              type: "info",
            });
          },
        }
      }
    },

    circleArticlesEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.circlesViewSet + this.$route.params.articleId + "/", [
        "ordering=-created",
        [`liked_details=true`],
      ]);
      return url;
    },

    commentformFields() {
      return {
        text: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Comment",
          md: 12,
          required: false,
          disabled: this.articleObject.creator.id != this.currentUser.id,
          max: 3000,
        },
        images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        }
      };
    },
  },

  methods: {
    async getCircleArticle() {
      var url =
        this.endpoints.circlesViewSet + this.$route.params.articleId + "/";
        this.articleObject = await this.api.call(this.essentials, url);
        if (this.articleObject.liked_by.includes(this.currentUser.id)) {
          this.is_liked = true;
        }
    },

    async showFullScreenCarousel(image, imageList) {
      this.initialImage = image;
      this.fullScreenImageOverlay = true;
      this.fullScreenCarouselImages = imageList;
    },

    openCommentSheet(){
      this.commentCreateEditSheet.obj.user = this.currentUser.id;
      this.commentCreateEditSheet.obj.article = this.articleObject.id; 
      this.commentCreateEditSheet.obj.institution_name = 
        this.currentUser.custom_institution_name ? this.currentUser.custom_institution_name : 
                                                   this.currentUser.institution.name;
      this.commentCreateEditSheet.visible = true;
    },

    async commentCreatedOrEdited(commentObj) {
      if (this.articleObject.comments) {
        var isEditing = this.commentCreateEditSheet.editId != null;
        if(isEditing) {
          var comment = this.articleObject.comments.find(comment => comment.id == commentObj.id);
          if(comment) Object.assign(comment, commentObj);
        }
        else this.articleObject.comments.unshift(commentObj);
      }
      this.commentCreateEditSheet.visible = false;
    },

    getCommentDropdownOptions(comment) {
      var self = this;
      return [
        {
          value: 1, 
          text: "Edit", 
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.commentCreateEditSheet.obj.article = self.articleObject.id;
            self.commentCreateEditSheet.obj.user = comment.user.id;
            self.commentCreateEditSheet.obj.institution_name = 
            self.currentUser.custom_institution_name ? self.currentUser.custom_institution_name : 
                                                       self.currentUser.institution.name;
            self.commentCreateEditSheet.editId = comment.id;
            self.commentCreateEditSheet.visible = true;
          }                  
        }, 
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedCommentId = comment.id;
            self.showDeleteCommentDialog = true;
          }          
        }
      ];
    },

    async deleteComment() {
      var url = this.endpoints.circleCommentViewSet + this.toBeDeletedCommentId+ "/";
      this.showDeleteCommentDialog = false;
      var response = await this.api.call(
        this.essentials,
        url, 
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Comment has been successfully deleted!",
          type: "success",
        });
        this.articleObject.comments = this.articleObject.comments.filter(comment => comment.id != this.toBeDeletedCommentId);
      }
      this.toBeDeletedCommentId = null;
    },

    async onRefresh() {
      this.getCircleArticle();
    },

    async getLikedByDetails() {
      var url = this.Helper.addUrlParams(this.endpoints.circlesViewSet + this.$route.params.articleId + "/", [
        "ordering=-created",
        [`liked_details=true`],
      ]);

      var response = await this.api.call(this.essentials, url);

      if (response) {
        this.likedByDetails = response.liked_by;
        this.likesBottomSheetVisible = true;
      }
    },

    async toggleLike() {
      var patch_body;
      var url = this.endpoints.circlesViewSet + this.$route.params.articleId + "/";
      if (this.is_liked) {
        this.articleObject.liked_by.forEach(element => {
          if (element == this.currentUser.id) {
            this.articleObject.liked_by.splice(this.articleObject.liked_by.indexOf(element), 1);
          }
        });
        // console.log("167: ", this.articleObject.liked_by)
      } else {
        this.articleObject.liked_by.push(this.currentUser.id);
      }
      patch_body = {
          liked_by: this.articleObject.liked_by,
      }
      var response = await this.api.call(this.essentials,url,this.api.Methods.PATCH,patch_body)
      if (response) {
        this.articleObject = response;
      }
      this.is_liked = !this.is_liked;

    }
  },

  async created() {
    await this.getCircleArticle();
  },
};
</script>

<style>
</style>